import View from './view.js'
import React, { useMemo } from 'react'

export default function Logic(props) {
  let columnDefs = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'id',
      },
      {
        field: 'amount',
        headerName: 'amount',
      },
      {
        field: 'amount_platform_fee',
        headerName: 'amount_platform_fee',
      },
      {
        field: 'balance',
        headerName: 'balance',
      },
      {
        field: 'notes',
        headerName: 'notes',
      },
      {
        field: 'has_correction',
        headerName: 'has_correction',
      },
      {
        field: 'payment_type',
        headerName: 'payment_type',
      },
      {
        field: 'payments_transaction_id',
        headerName: 'payments_transaction_id',
      },
      {
        field: 'sys_created_at',
        headerName: 'sys_created_at',
      },
    ],
    []
  )
  return <View {...props} columnDefs={columnDefs} />
}
