import { useEffect, useMemo, useRef } from 'react'
import uuid from 'uuid/v4.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  // Keep the payment page details outside of the memo so they don't invalidate the payment
  // form and we can call them when needed. This code should be deleted once Payabli
  // we don't need fixes their temporary token implementation since we won't need the payment
  // form at that point anymore.
  // The function should return an object with the following shape:
  // { amount: string, firstName: string, lastName: string, customerNumber: string }
  // The demographics belong to the payor.
  let getPaymentPageDetails = useRef(props.getPaymentPageDetails)
  useEffect(() => {
    getPaymentPageDetails.current = props.getPaymentPageDetails
  }, [props.getPaymentPageDetails])

  return useMemo(() => {
    return {
      ...data,
      allow_reuse:
        typeof props.allowReuse === 'boolean'
          ? props.allowReuse
          : data.allow_reuse,
      allow_location_choice:
        typeof props.allowLocationChoice === 'boolean'
          ? props.allowLocationChoice
          : data.allow_location_choice,
      xid: props.xid,
      containerId: `${data.containerId}-${uuid()}`,
      getPaymentPageDetails: () =>
        typeof getPaymentPageDetails.current === 'function'
          ? getPaymentPageDetails.current()
          : null,
      account_type: props.paymentAccountType,
    }
  }, [
    data,
    props.allowReuse,
    props.allowLocationChoice,
    props.xid,
    props.paymentAccountType,
  ])
}
