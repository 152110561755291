// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PaymentProviders/Accounts/mutation-set-default.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_PaymentProviders_Accounts(
  $provider_location_account_id: uuid!
  $location_id: uuid!
  $vaxiom_location_id: numeric!
  $should_insert: Boolean!
) {
  update_payments_provider_location_accounts(
    _set: { is_default: false }
    where: { is_default: { _eq: true }, location_id: { _eq: $location_id } }
  ) {
    returning {
      id
    }
  }

  update_payments_provider_location_accounts_by_pk(
    pk_columns: { id: $provider_location_account_id }
    _set: { is_default: true }
  ) {
    id
  }

  insert_vaxiom_application_properties_one(
    object: {
      message_key: "new.pay_app.enabled"
      organization_id: $vaxiom_location_id
      message_value: "true"
    }
    on_conflict: {
      constraint: idx_364469_primary
      update_columns: [message_value]
    }
  ) @include(if: $should_insert) {
    message_key
    message_value
  }
}

`