// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PaymentProviders/Accounts/Content/ProviderAccount/AccountLocations/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_PaymentProviders_Accounts_Content_ProviderAccount_AccountLocations($location_id: uuid!, $account_id: String!) {
  payments_provider_location_accounts(
    where: {
      account_id: { _eq: $account_id }
      location_id: { _neq: $location_id }
      location: { deleted: { _eq: false } }
      status: { _eq: active }
    }
    order_by: { created_at: desc }
  ) {
    id
    location {
      id
      name
    }
  }
}

`