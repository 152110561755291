// this is an autogenerated file from DesignSystem/PaymentProvider/PaymentProviderLocation/PlatformFeeWarning/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PaymentProvider_PaymentProviderLocation_PlatformFeeWarning_Content($provider_location_account_id: uuid!) {
  payments_provider_location_accounts_by_pk(id: $provider_location_account_id) {
    id
    platform_fee_card
  }
}

`