import mutationSaveSettings from './mutation-save-settings.graphql.js'
import mutationSetDefault from './mutation-set-default.graphql.js'
import mutationUpdateStatus from './mutation-update-status.graphql.js'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { percentage } from 'Data/format.js'

let PERCENTAGE_ALLOWED = {
  MIN: 2.79,
  MAX: 5,
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let [, notify] = useNotifications()
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'id',
  })

  let vaxiom_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'vaxiom_id',
  })

  let user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'user.id',
  })

  let [, executeMutationSaveSettings] = useMutation(mutationSaveSettings)
  let [, executeMutationSetDefault] = useMutation(mutationSetDefault)
  let [, executeMutationUpdateStatus] = useMutation(mutationUpdateStatus)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'change_default': {
        if (!args.account_id) {
          return
        }

        let mutationResponse = await executeMutationSetDefault({
          location_id,
          provider_location_account_id: args.account_id,
          vaxiom_location_id,
          should_insert: args.provider === 'payabli',
        })

        if (mutationResponse.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }

        notify(notifySuccess('Default provider set!'))
        return
      }

      case 'verify_paypoint':
      case 'invalidate_paypoint': {
        if (!args.provider_location_account_id || !user_id) {
          return
        }

        let mutationResponse = await executeMutationUpdateStatus({
          id: args.provider_location_account_id,
          user_id,
          status: args.type === 'verify_paypoint' ? 'active' : 'invalidated',
          is_default: args.type === 'verify_paypoint',
          location_id,
        })

        if (mutationResponse.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }

        notify(
          notifySuccess(
            args.type === 'verify_paypoint'
              ? 'Payment provider approved!'
              : 'Payment Provider rejected!'
          )
        )
        return
      }

      case 'save_settings': {
        if (
          typeof args.platform_fee_card === 'number' &&
          (args.platform_fee_card < PERCENTAGE_ALLOWED.MIN ||
            args.platform_fee_card > PERCENTAGE_ALLOWED.MAX)
        ) {
          notify(
            notifyError(
              `The platform fee must be a value between ${percentage(
                PERCENTAGE_ALLOWED.MIN
              )} and ${percentage(PERCENTAGE_ALLOWED.MAX)}.`
            )
          )
          return
        }

        let saveSettingsMutationResponse = await executeMutationSaveSettings({
          account_id: args.account_id,
          platform_fee_card: args.platform_fee_card,
          greyfinch_pay_app_enabled: args.greyfinch_pay_app_enabled,
          organization_id: vaxiom_location_id,
        })

        if (saveSettingsMutationResponse.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }

        notify(notifySuccess('Updated successfully'))
        return
      }

      case 'discard_changes': {
        change(next => {
          let index = next.findIndex(
            item => item.id === args.provider_account_id
          )

          if (index !== -1) {
            next[index] = originalValue[index]
          }
        })
        return
      }
      default: {
        return
      }
    }
  }
}
