import { useDataValue } from 'Simple/Data'

export function useIsDisabled(props) {
  let is_default = useDataValue({
    context: 'provider_account',
    path: 'is_default',
    viewPath: props.viewPath,
  })

  let is_support = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.is_support',
  })

  return is_default || !is_support
}
