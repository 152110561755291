// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsPostPayment/Content/AccountSelect/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_EmbeddableCoreMessageListener_PaymentsPostPayment_Content_AccountSelect($patient_id: uuid!) {
  vaxiom_patients(where: { _id: { _eq: $patient_id } }) {
    id
    _id
    payment_accounts {
      payment_account {
        account_type
      }
      payor {
        _id
        id
        first_name
        greeting
        last_name
      }
      insurance_company {
        id
        carrier_name
      }
      insurance_plan {
        id
        number
      }
      insured_id
      payment_account_id
    }
  }
}

`