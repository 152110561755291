import { useDataChange } from 'Simple/Data'

let MIN_PLATFORM_FEE = 2.79

export function useOnChange(props) {
  let change = useDataChange({
    context: 'provider_account',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.platform_fee_card_enabled = value
      // if the current platform fee is null and I'm enabling the flag
      // I set to the min value
      if (value) {
        next.platform_fee_card = next.platform_fee_card || MIN_PLATFORM_FEE
      }
    })
  }
}
