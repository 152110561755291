// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PaymentProviders/Accounts/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_PaymentProviders_Accounts($location_id: uuid!) {
  payments_provider_location_accounts(
    where: { location_id: { _eq: $location_id } }
    order_by: { created_at: desc }
  ) {
    id
    is_default
    account_id
    provider
    status
    updated_at
    created_by
    description
    platform_fee_card
    location {
      id
      name
      feature_flags {
        is_pay_app_enabled
      }
    }
    updated_by_user {
      id
      vaxiom_person {
        id
        first_name
        last_name
        greeting
      }
    }
  }
}

`