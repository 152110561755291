import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'provider_accounts',
    viewPath: props.viewPath,
  })

  let provider_account_id = useDataValue({
    context: 'provider_account',
    path: 'id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'discard_changes',
      provider_account_id,
    })
  }
}
