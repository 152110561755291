// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PaymentProviders/Accounts/mutation-save-settings.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_PaymentProviders_Accounts(
  $account_id: String!
  $platform_fee_card: numeric
  $organization_id: numeric!
  $greyfinch_pay_app_enabled: Boolean!
) {
  update_payments_provider_location_accounts(
    where: { account_id: { _eq: $account_id } }
    _set: { platform_fee_card: $platform_fee_card }
  ) {
    returning {
      id
    }
  }
  insert_vaxiom_application_properties_one(
    object: {
      organization_id: $organization_id
      message_key: "new.pay_app.enabled"
      message_value: "true"
    }
    on_conflict: {
      constraint: idx_364469_primary
      update_columns: [message_value]
    }
  ) @include(if: $greyfinch_pay_app_enabled) {
    organization_id
    message_key
    message_value
  }
  delete_vaxiom_application_properties(
    where: {
      organization_id: { _eq: $organization_id }
      message_key: { _eq: "new.pay_app.enabled" }
    }
  ) @skip(if: $greyfinch_pay_app_enabled) {
    affected_rows
  }
}

`