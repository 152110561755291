/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  return {
    variables: {
      type: props.method,
      person_id: props.person?._id,
      token_ids: [props.tokenId].filter(Boolean),
      include_platform_fee_card:
        props.paymentAccountType !== 'InsurancePaymentAccount',
    },
    requestPolicy: 'cache-and-network',
  }
}
