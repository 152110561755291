import { useDataValue } from 'Simple/Data'

export function useIsDisabled(props) {
  let provider = useDataValue({
    viewPath: props.viewPath,
    context: 'provider_account',
    path: 'provider',
  })
  let is_support = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.is_support',
  })

  return !is_support || provider !== 'payabli'
}
