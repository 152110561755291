import { useDataChange, useDataOriginalValue } from 'Simple/Data.js'
import { equals } from 'Data/aggregate'

export default function useListItemDataOnChange(props) {
  let change = useDataChange({
    context: 'provider_accounts',
    viewPath: props.viewPath,
  })

  let original = useDataOriginalValue({
    context: 'provider_accounts',
    viewPath: props.viewPath,
  })

  return function onChange(next) {
    change(inext => {
      inext[props.index] = {
        ...next,
        has_changes: !equals(
          {
            platform_fee_card: next.platform_fee_card,
            platform_fee_card_enabled: next.platform_fee_card_enabled,
            greyfinch_pay_app_enabled: next.greyfinch_pay_app_enabled,
          },
          {
            platform_fee_card: original[props.index].platform_fee_card,
            platform_fee_card_enabled:
              original[props.index].platform_fee_card_enabled,
            greyfinch_pay_app_enabled:
              original[props.index].greyfinch_pay_app_enabled,
          }
        ),
      }
    })
  }
}
