import { useDataFormat, useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'provider_accounts',
    viewPath: props.viewPath,
  })

  let account_id = useDataValue({
    context: 'provider_account',
    path: 'account_id',
    viewPath: props.viewPath,
  })

  let platform_fee_card_enabled = useDataValue({
    context: 'provider_account',
    path: 'platform_fee_card_enabled',
    viewPath: props.viewPath,
  })

  let platform_fee_card = useDataValue({
    context: 'provider_account',
    path: 'platform_fee_card',
    viewPath: props.viewPath,
  })
  let greyfinch_pay_app_enabled = useDataFormat({
    context: 'provider_account',
    path: 'greyfinch_pay_app_enabled',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'save_settings',
      account_id,
      platform_fee_card: platform_fee_card_enabled ? platform_fee_card : null,
      greyfinch_pay_app_enabled: !!greyfinch_pay_app_enabled,
    })
  }
}
