import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'provider_accounts',
  })

  let provider_location_account_id = useDataValue({
    viewPath: props.viewPath,
    context: 'provider_account',
    path: 'id',
  })

  return function onClick() {
    submit({ type: 'invalidate_paypoint', provider_location_account_id })
  }
}
