import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(d => {
      let description =
        d.provider === 'payabli' && d.description
          ? JSON.parse(d.description)
          : {}
      if ('token' in description) {
        description['token'] = atob(description['token'])
      }
      return {
        ...d,
        description,
        greyfinch_pay_app_enabled:
          d.location?.feature_flags?.is_pay_app_enabled &&
          d.provider === 'payabli',
        platform_fee_card_enabled: d.platform_fee_card !== null,
        platform_fee_card: d.platform_fee_card,
        has_changes: false,
      }
    })
  }, [data])
}
