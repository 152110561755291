import { textWithHTML } from 'Data/format.js'
import { toSentence } from 'Data/format'

export function getText(locations) {
  return textWithHTML(
    `The configuration will apply for the following locations as they share the same Payabli Account ID: <b>${toSentence(
      locations.map(item => item.location.name)
    )}</b>. All card transactions will include the configured platform fee.`
  )
}
