// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PaymentProviders/Accounts/mutation-update-status.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_PaymentProviders_Accounts(
  $id: uuid!
  $user_id: uuid!
  $status: payments_provider_statuses_enum!
  $location_id: uuid!
  $is_default: Boolean!
) {
  update_payments_provider_location_accounts(
    _set: { is_default: false }
    where: { is_default: { _eq: true }, location_id: { _eq: $location_id } }
  ) @include(if: $is_default) {
    returning {
      id
    }
  }

  update_payments_provider_location_accounts_by_pk(
    _set: {
      status: $status
      status_changed_at: "now()"
      status_changed_by_id: $user_id
      is_default: $is_default
    }
    pk_columns: { id: $id }
  ) {
    id
  }
}

`