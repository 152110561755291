import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let account_id = useDataValue({
    viewPath: props.viewPath,
    context: 'provider_account',
    path: 'account_id',
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'id',
  })

  return {
    variables: {
      account_id,
      location_id,
    },
    pause: !account_id,
  }
}
