import View from './view.js'
import { useDataValue } from 'Simple/Data'

export default function Logic(props) {
  let status = useDataValue({
    context: 'provider_account',
    path: 'status',
    viewPath: props.viewPath,
  })

  return <View {...props} status={status} />
}
