import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let provider_location_account_id = useDataValue({
    context: 'payment',
    path: 'provider_location_account_id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      provider_location_account_id,
    },
    pause: !provider_location_account_id,
  }
}
