export function getView(
  method,
  account_type,
  state,
  provider_location_account_id
) {
  return (
    method === 'card' &&
    account_type !== 'InsurancePaymentAccount' &&
    state === 'ready' &&
    provider_location_account_id !== null &&
    provider_location_account_id !== undefined
  )
}
